<template>
    <Toast />
    <ConfirmDialog :group="baseEntityName"></ConfirmDialog>

    <DataTable ref="dt"
        :key="dataTableKey"
        :value="data"
        v-model:selection="selectedItem"
        :metaKeySelection="true"
        :lazy="true"
        :paginator="true"
        :rows="rows"
        :rowsPerPageOptions="[5, 10, 50, 100, 250]"
        :rowHover="true"
        :totalRecords="totalRecords"
        :loading="loading"
        :resizableColumns="true" columnResizeMode="expand" 
        :scrollable="true"
        :class="sizeClass"
        scrollHeight="77vh"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="{totalRecords} kaydın {first} ile {last} arası gösteriliyor"
        @page="onPage($event)"
        @sort="onSort($event)"
        @row-dblclick="onDblClick($event)"
        @row-select="onRowSelect($event)"
        :globalFilterFields="['global']"
        responsiveLayout="scroll">
        <template #empty>
            Kayıt Bulunamadı.
        </template>
        <template #loading>
            Veriler yükleniyor, lütfen bekleyiniz...
        </template>
        
        <template #header v-if="isHeaderVisible">
            <div class="flex justify-content-between">
                <div>
                    <div class="flex flex-wrap card-container green-container">
                        <div class="flex align-items-center justify-content-center mr-2" v-if="isEntityImageVisible">
                            <img id="footer-logo" :src="entityImageUrl" />
                        </div>
                        <div class="flex align-items-center justify-content-center m-2 font-bold indigo-color text-2xl" v-if="isNewButtonVisible">
                            <Button type="button" icon="pi pi-plus" label="Yeni" class="p-button-outlined" @click="GetNewWindow()" />
                        </div>
                        <div class="flex align-items-center justify-content-center m-2 font-bold indigo-color text-2xl">
                            <Button type="button" icon="pi pi-refresh" label="Yenile" class="p-button-outlined" @click="OnRefresh()" />
                        </div>
                        <div class="flex align-items-center justify-content-center m-2 font-bold indigo-color text-2xl" >
                            <Button type="button" icon="pi pi-trash" label="Sil" class="p-button-outlined" @click="OnDelete()" v-if="selectedItem.length > 0" />
                        </div>
                        <div v-if="isOzelIslem" class="flex align-items-center justify-content-center m-2 font-bold indigo-color text-2xl" >
                            <Button type="button" icon="pi pi-cog" :label="ozelIslemButtonAdi" class="p-button-outlined" @click="OnOzelIslem()" v-if="selectedItem.length > 0" />
                        </div>
                        <div class="flex align-items-center justify-content-center m-2 font-bold indigo-color text-2xl" v-if="isYazdirYetki" >
                            <Button type="button" icon="pi pi-print" label="Yazdır" class="p-button-outlined" @click="OnPrint()" />
                        </div>
                        <div class="flex align-items-center justify-content-center m-2 font-bold indigo-color text-2xl">
                            <Button type="button" icon="pi pi-angle-down" :label="savedQueryName" class="p-button-outlined" @click="toggle" aria-haspopup="true" aria-controls="overlay_menu"/>
                            <Menu id="overlay_menu" ref="menu" :model="menu_items" :popup="true" />
                        </div>
                        <div class="flex align-items-center justify-content-center m-2 font-bold indigo-color text-2xl" v-if="selectedItem.length > 0" >
                            <Button severity="warning" :label="selectedItem.length + ' seçildi'"  />
                        </div>
                        
                    </div>
                </div>
                <div>
                    <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText v-model="filters['global'].value" @keydown.enter="onFilter()" placeholder="Anahtar kelime ile ara" />
                    </span>
                </div>
            </div>
        </template>
        <Column :selectionMode="getSelectionMode" style="flex: 0 0 3rem" headerStyle="width: 3rem"></Column>
        <Column v-for="(col, index) in data_columns" :field="col.name" :header="col.label" :key="index" :sortable="col.disableSorting == 1 ? false : true" :style="'flex: 0 0 ' + col.width + '%;'">
            <template #body="slotProps">
                <div v-if="col.name == 'description'" v-tooltip.left="slotProps.data[col.name]" >{{ stringLength(slotProps.data[col.name]) }}</div> 
                <div v-else v-html="columnFieldFormat(slotProps.data, col)" />
            </template>
        </Column>
    </DataTable>
</template>

<script>
import CrmService from "../service/CrmService";
import { FilterMatchMode} from 'primevue/api';

export default {
    props: {
        baseEntityName: {
            type: String,
            required: true
        },
        isHeaderVisible: {
            type: Boolean,
            required: true
        },
        isNewButtonVisible: {
            type: Boolean,
            required: false,
            default: false
        },
        isUserViewVisible: {
            type: Boolean,
            required: false,
            default: false
        },
        isEntityImageVisible: {
            type: Boolean,
            required: false,
            default: true
        },
        isLookupSearch: {
            type: Boolean,
            required: false,
            default: false
        },
        isSubGrid: {
            type: Boolean,
            required: false,
            default: false
        },
        doubleClickOpenEntity: {
            type: Boolean,
            required: false,
            default: false
        },
        newWindowViewName: {
            type: String,
            required: false
        },
        options: {
            type: Object,
            required: false
        },
        rows: {
            type: Number,
            required: false,
            default: 50
        },
        sizeClass: {
            type: String,
            required: false,
            default: "p-datatable-sm"
        },
        isOzelIslem: {
            type: Boolean,
            required: false,
            default: false
        },
        ozelIslemButtonAdi: {
            type: String,
            required: false,
            default: "İşlem"
        },
        yetkiEntityName: {
            type: String,
            required: false,
            default: ""
        }
    },
    data: function () {
        return {
            loading: true,
            crmService: null,
            savedQueryId: "",
            savedQueryName: "",

            savedQueryId_original: "",
            savedQueryName_original: "",

            firstOpen: true,

            dataTableKey: 0,

            data: [],
            selectedItem: [],
            data_columns: [],
			lazyParams: {},
            filters: {
				'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
			},
            totalRecords: 0,

            menu_items: [ { label: 'Sistem Görünümleri', items: [] } ]
        }
    },
    async created() {
		this.crmService = new CrmService();
        if (this.options.views) {
            debugger;

            this.savedQueryId = this.options.views[0].savedQueryId;
            this.savedQueryName = this.options.views[0].viewName;

            if (this.options.forceSavedQueryId != undefined) {
                const filteredView = this.options.views.find(x => x.savedQueryId == this.options.forceSavedQueryId);
                if (filteredView) {
                    this.savedQueryId = filteredView.savedQueryId;
                    this.savedQueryName = filteredView.viewName;

                    this.savedQueryId_original = this.savedQueryId;
                    this.savedQueryName_original = this.savedQueryName;
                }
            }

            this.options.views.forEach(element => {
                this.menu_items[0].items.push(
                    {
                        label: element.viewName,
                        icon: 'pi pi-bars',
                        command: () => {
                            this.savedQueryId = element.savedQueryId;
                            this.savedQueryName = element.viewName;

                            this.filters['global'].value = null;
                            debugger;
                            this.loadLazyData();
                        }
                    }
                );
            });
        }

        if (this.options.filterParent) {
            console.log(this.options.filterParent);
            if (this.options.filterParent.length > 0) {
                if (this.options.filterParent[0].value == undefined) {
                    return false;
                }
            }
        }
        debugger;

        //PREVENT SYSTEM VIEWS
        let preventSystemViews = true;
        if (this.options.preventSystemViews) {
            if (this.options.preventSystemViews == true) {
                preventSystemViews = false;
            }
        }

        if (preventSystemViews) {
            await this.getSystemViews(this.baseEntityName);
        }

        //PREVENT USER VIEWS
        let preventUserViews = true;
        if (this.options.preventUserViews) {
            if (this.options.preventUserViews == true) {
                preventUserViews = false;
            }
        }
        
        if (preventUserViews) {
            this.menu_items.push({ label: 'Kullanıcı Görünümleri', items: [] });
            await this.getUserViews(this.baseEntityName);
        }
	},
    mounted: function () {
		this.loading = true;
				
		this.lazyParams = {
			first: 0,
			rows: this.$refs.dt.rows,
			sortField: null,
			sortOrder: null,
			filters: this.filters
		};

		this.loadLazyData();

        window.addEventListener("message", (event)=>{
			var origin = event.origin;

			if (window.origin == origin && event.isTrusted && event.data.tag != undefined) {
				debugger;
				if (event.data.tag == "refresh") {
					this.loadLazyData();
				}
			}
		});
	},
    methods: {
        async getSystemViews(entityName) {
			debugger;
            this.loading = true;

            let systemViews = await this.crmService.GetSavedViewsSystem(entityName);

            if (this.isLookupSearch == true) {
                debugger;
                if (this.options.forceSavedQueryId) {
                    let forceSavedQuery = systemViews.jsonData.find(data => data.savedQueryId == this.options.forceSavedQueryId.toLowerCase());
                    if (forceSavedQuery != undefined) {
                        this.savedQueryId = forceSavedQuery.savedQueryId;
                        this.savedQueryName = forceSavedQuery.viewName;
                    }
                }
                else {
                    let isDefaultViews = systemViews.jsonData.find(data => data.IsLookupSearchView === true);
                    this.savedQueryId = isDefaultViews.savedQueryId;
                    this.savedQueryName = isDefaultViews.viewName;
                }
            }
            else {
                if (this.options.forceSavedQueryId) {
                    let forceSavedQuery = systemViews.jsonData.find(data => data.savedQueryId == this.options.forceSavedQueryId.toLowerCase());
                    if (forceSavedQuery != undefined) {
                        this.savedQueryId = forceSavedQuery.savedQueryId;
                        this.savedQueryName = forceSavedQuery.viewName;
                    }
                }
                else {
                    let isDefaultViews = systemViews.jsonData.find(data => data.IsDefault === true);
                    if (isDefaultViews) {
                        this.savedQueryId = isDefaultViews.savedQueryId;
                        this.savedQueryName = isDefaultViews.viewName;
                    }
                }
            }

            this.savedQueryId_original = this.savedQueryId;
            this.savedQueryName_original = this.savedQueryName;
            
            this.loadLazyData();

            systemViews.jsonData.forEach(element => {
                this.menu_items[0].items.push(
                    {
                        label: element.viewName,
                        icon: 'pi pi-bars',
                        command: () => {
                            this.savedQueryId = element.savedQueryId;
                            this.savedQueryName = element.viewName;
                            this.filters['global'].value = null;
                            this.loadLazyData();
                        }
                    }
                );
            });

            this.loading = false;
        },
        async getUserViews(entityName) {
            this.loading = true;

            let userViews = await this.crmService.GetSavedViewsUser(entityName);
            userViews.jsonData.forEach(element => {
                this.menu_items[1].items.push(
                    {
                        label: element.viewName,
                        icon: 'pi pi-bars',
                        command: () => {
                            this.savedQueryId = element.savedQueryId;
                            this.savedQueryName = element.viewName;
                            this.filters['global'].value = null;
                            this.loadLazyData();
                        }
                    }
                );
            });
            
            this.loading = false;
        },
        OnRefresh() {
            this.selectedItem = [];
            if (this.filters) {
                if (this.filters.global) {
                    if (this.filters.global.value) {
                        this.filters.global.value = '';
                    }
                }
            }

            /*
            this.savedQueryId = this.savedQueryId_original;
            this.savedQueryName = this.savedQueryName_original;
            this.lazyParams["SavedQueryId"] = this.savedQueryId_original;
            this.lazyParams["SavedQueryName"] = this.savedQueryName_original;
            */
            debugger;
            this.loadLazyData();
        },
        OnDelete() {
            debugger;
            if (this.selectedItem.length > 0) {
                this.$confirm.require({
                    message: 'Seçili ' + this.selectedItem.length + ' kayıt silinecek emin misiniz?',
                    header: 'Silme Onayı',
                    icon: 'pi pi-exclamation-triangle',
                    acceptClass: 'p-button-danger',
                    acceptLabel: 'Evet',
                    rejectLabel: 'Hayır',
                    accept: async () => {

                        const entityIdList = this.selectedItem.map(obj => obj.entityId);
                        const postData = {
                            entityName : this.baseEntityName,
                            list: entityIdList,
                            yetkiEntityName: this.yetkiEntityName
                        };
                        
                        let loader = this.$loading.show({
                            container: this.$refs.smsContainer
                        });

                        try {
                            const response = await this.crmService.DeleteRecordList(postData);
                            debugger;
                            if (response) {
                                if (response.hata == true) {
                                    this.$toast.add({severity:'error', summary: 'Silinemedi', detail: response.hataAciklamasi, life: 5000});
                                }else {
                                    loader.hide();
                                    setTimeout(() => {
                                        this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Seçili kayıtlar başarıyla silindi', life: 3000});
                                        this.selectedItem = [];
                                        debugger;
                                        this.$emit('onRowDelete', null);
                                        this.loadLazyData();
                                    }, 500);
                                }
                            }
                        } catch (error) {
                            this.$toast.add({severity:'error', summary: 'Bir Hata Oluştu', detail: error.message, life: 3500});
                        }
                        finally {
                            loader.hide();
                        }
                    }
                });
            }
            else {
                this.$toast.add({severity:'error', summary: 'Hata', detail: 'Lütfen silinecek kayıtları seçiniz!', life: 3500});
            }
            
        },
		loadLazyData() {
            if (this.savedQueryId) {
                debugger;
                this.loading = true;
                this.lazyParams["baseEntityName"] = this.baseEntityName;
                this.lazyParams["SavedQueryId"] = this.savedQueryId;
                this.lazyParams["firstOpen"] = this.firstOpen;
                this.lazyParams["Options"] = this.options;
                this.lazyParams.filters = this.filters;

                this.crmService.GetViewData(this.lazyParams)
                .then(data => {
                    if (data == undefined) {
                        alert('Json Verisi yüklenemedi!');
                    }
                    else {
                        debugger;

                        if (data.hata) {
                            this.$toast.add({severity:'error', summary: 'Erişim Hatası', detail: data.hataAciklamasi, life: 5000});
                        }
                        else {
                            this.data = data.data;
                            this.data_columns = data.columns;
                            this.columnSizeDuzelt();
                            this.totalRecords = data.totalRecords;

                            if (data.savedQueryName) {
                                this.savedQueryId = data.savedQueryId;
                                this.savedQueryName = data.savedQueryName;
                            }
                            
                            this.loading = false;
                            this.$emit('totalRecords', data.totalRecords);
                        }
                    }
                })
                .catch(error => {
                    debugger;
                    console.log(error);
                    this.$toast.add({severity:'error', summary: 'Sistemsel Erişim Hatası', detail: this.baseEntityName + ' - ' + error, life: 5000});
                });
            }
        },
        columnFieldFormat(data, col){
            const lookupData = data[col.name + "lookupData"];
            if (lookupData) {
                const lookupFieldListesi = ["account", "contact", "product", "bm_unite", "incident", "bm_yedekparca", "bm_yedekparcateklifsiparis"];
                const logicalName = lookupData.split('|')[0];
                if (lookupFieldListesi.includes(logicalName)) {
                    const entityId = lookupData.split('|')[1];
                    const entityUrl = process.env.VUE_APP_URL + "#/" + logicalName + "/" + entityId;
                    //return "<a class='lookuplink' href='" + entityUrl + "' target='_blank'> " + data[col.name] + "</a>";
                    var htmlKod = '<a class="lookuplink" href="'+entityUrl+'" onclick="window.open(\''+entityUrl+'\', \'_blank\', \'directories=no,menubar=no,status=no,titlebar=no,toolbar=no\'); return false;">'+data[col.name]+'</a>';

                    return htmlKod;
                    
                }
                else {
                    return data[col.name];
                }
            }
            else {
                if (this.baseEntityName == 'bm_yedekparcateklifsiparis' && col.name == 'bm_pesinmi' && data["bm_pesinmi"] == 'Evet') {
                    return "<div class='dd'>" + data[col.name] + "</div>";
                }
                else if (this.baseEntityName == 'appointment' && col.name == 'description') {
                    return "<div v-tooltip.left=\"'asf'\">" + data[col.name] + "</div>";
                }
                else if (this.baseEntityName == 'account' && col.name == 'telephone1') {
                    return "<a href='tel:"+data[col.name]+"'>" + data[col.name] + "</a>";
                }
                else {
                    return data[col.name];
                }
            }
        },
        stringLength(value){
            if (value) {
                if (value.length > 40) {
                    return value.substring(0,40);
                } else {
                    return value;
                }
            }
            else {
                return value;
            }
        },
        onPage(event) {
            this.lazyParams = event;
            this.loadLazyData();
        },
        onSort(event) {
            this.lazyParams = event;
            this.loadLazyData();
        },
        onFilter() {
			debugger;
            this.savedQueryName = 'Arama Sonuçları';
            this.loadLazyData();
        },
        onDblClick(event) {
			console.log(event);
            debugger;
            if (this.doubleClickOpenEntity == true) {
                let features = 'directories=no,menubar=no,status=no,titlebar=no,toolbar=no';

                let routeData = this.$router.resolve({ name: this.baseEntityName, params: { id: event.data.entityId } });
                window.open(routeData.href, '_blank', features);
            }
            else {
                this.$emit('onRowSelect', event.data);
            }
        },
		onRowSelect(event) {
			console.log(event);
            this.$emit('onRowSelectedData', event.data);
        },
        onSortChange(event){
            const value = event.value.value;
            const sortValue = event.value;

            if (value.indexOf('!') === 0) {
                this.sortOrder = -1;
                this.sortField = value.substring(1, value.length);
                this.sortKey = sortValue;
            }
            else {
                this.sortOrder = 1;
                this.sortField = value;
                this.sortKey = sortValue;
            }
        },
        OnPrint(){
            
            localStorage.setItem(this.baseEntityName + '_print_array', JSON.stringify(this.getSelectedEntityIds));

            let features = 'directories=no,menubar=no,status=no,titlebar=no,toolbar=no';

            let routeData = this.$router.resolve({ name: 'printgrid', params: { baseEntityName: this.baseEntityName, savedQueryId: this.savedQueryId, savedQueryName: this.savedQueryName } });
            window.open(routeData.href, '_blank', features);
        },
        OnOzelIslem(){
            this.$emit('onOzelIslem', this.selectedItem);
        },
        toggle(event) {
            this.$refs.menu.toggle(event);
            this.firstOpen = false;
        },
        GetNewWindow(){
            debugger;

            let windowsName = '';
            if (this.newWindowViewName) {
                windowsName = this.newWindowViewName;
            }
            else {
                windowsName = this.baseEntityName + 'new';
            }

            let features = 'directories=no,menubar=no,status=no,titlebar=no,toolbar=no';
			let routeData = this.$router.resolve({ name: windowsName });
			window.open(routeData.href, '_blank', features);
        },
		formatDate(value){
            return value.toLocaleDateString('tr-TR', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
				hour: '2-digit',
				minute: '2-digit',
				timeZone: 'UTC'
            });
        },
        columnSizeDuzelt(){
            let toplamWidth = 0;
            if (this.data_columns) {
                this.data_columns.forEach(element => {
                    toplamWidth += parseInt(element.width);
                });

                toplamWidth = toplamWidth + 20;

                this.data_columns.forEach(element => {
                    element.width = parseFloat((parseFloat(element.width) / toplamWidth) * 100);
                });
            }
        }
	},
    computed: {
        profileData(){
			return this.$store.getters.getProfile;
		},
        isYazdirYetki(){
            if (this.profileData) {
                if (this.profileData.guvenlikSeviyeleri) {
                    const isSeviye = (this.profileData.guvenlikSeviyeleri.indexOf("Yazdır") > -1);
                    return isSeviye;
                }
            }

            return false;
		},
        getSelectedEntityIds(){
            if (this.selectedItem.length > 0) {
                return this.selectedItem.map(item => item.entityId);
            }
            else {
                return [];
            }
        },
		entityImageUrl() {
			return "layout/images/entityImages/" + this.baseEntityName + ".png";
		},
        getSelectionMode() {
            if (this.isLookupSearch) {
                return "single"
            }
            else {
                return "multiple"
            }
		},
	},
}
</script>


<style lang="scss" scoped>

	::v-deep(.p-datatable.p-datatable-customers) {
		.p-datatable-header {
			padding: 1rem;
			text-align: left;
			font-size: 1.5rem;
		}

		.p-paginator {
			padding: 1rem;
		}

		.p-datatable-thead > tr > th {
			text-align: left;
		}

		.p-datatable-tbody > tr > td {
			cursor: auto;
		}

		.p-dropdown-label:not(.p-placeholder) {
			text-transform: uppercase;
		}
	}

    ::v-deep(.dd) {
        border-radius: 2px;
        padding: .25em .5rem;
        font-weight: bold;
        font-size: 13px;
        letter-spacing: .3px;
        background: #FFCDD2;
        color: #C63737;
	}

	::v-deep(.p-paginator) {
		.p-paginator-current {
			margin-left: auto;
		}
	}

	.table-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.true-icon {
		color: lightseagreen;
	}

	.false-icon {
		color: orangered;
	}



    ::v-deep(.lookuplink:hover) {
        text-decoration: underline;
        color: rgb(0, 0, 0);
    }

	::v-deep(.p-datatable.p-datatable-customers) {
		.p-datatable-header {
			padding: 1rem;
			text-align: left;
			font-size: 1.5rem;
		}

		.p-paginator {
			padding: 1rem;
		}

		.p-datatable-thead > tr > th {
			text-align: left;
		}

		.p-datatable-tbody > tr > td {
			cursor: auto;
		}

		.p-dropdown-label:not(.p-placeholder) {
			text-transform: uppercase;
		}

        
	}

    
</style>  
