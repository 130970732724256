<template>
    <div class="flex flex-wrap card-container green-container mb-4">
        <div class="flex align-items-center justify-content-center mr-2">
            <img id="footer-logo" :src="entityImageUrl" />
        </div>
        <div class="flex align-items-center justify-content-center m-2 font-bold indigo-color text-2xl ">
            {{label}}
        </div>
    </div>
</template>


<script>
export default {
    props: {
        label: {
            type: String,
            required: true
        },
        entityName: {
            type: String,
            required: true
        }
    },
    computed: {
		entityImageUrl() {
			return "layout/images/entityImages/" + this.entityName + ".png";
		},
	},
}
</script>