module.exports = {
	checkPermissionModul(profileData, modulName){
		debugger;
		if (profileData) {
			console.log(profileData);
			if (profileData.moduller) {
				debugger;
				const filtered = profileData.moduller.filter(x => x.name == modulName);
				debugger;
				if (filtered.length > 0) {
					return true;
				} else {
					console.log('checkPermissionModul -> filtered.length == 0 geldi. ->' + modulName);
					return false;
				}
			}
			else {
				console.log('checkPermissionModul -> profileData.moduller boş geldi. ->' + modulName);
				return true;
			}
		}
		else {
			console.log('checkPermissionModul -> profileData boş geldi. ->' + modulName);
			return false;
		}
	},
}
