<template>
	<div class="pages-body login-page flex flex-column">

    <div class="align-self-center mt-auto mb-auto">
        <div class="pages-panel card flex flex-column">
            <div class="pages-header px-3 py-1">
                <img src="layout/images/logo_renkli.png" class="p-2" alt="Image" style="width: 100%; display: block;" />
            </div>

            <h4>Hoş geldiniz</h4>

            <div class="pages-detail p-mb-6 p-px-6">Lütfen Form CRM kullanıcı adı ve şifreniz ile oturum açınız.</div>
            <br><br>

            <form @submit.prevent="handleSubmit(!v$.$invalid)">

                <div class="input-panel flex flex-column px-6"  style="height:150px">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-envelope"></i>
                        </span>
                        <span class="p-float-label">
                            <InputText type="text" id="username" v-model="v$.username.$model" :class="{'p-invalid':v$.username.$invalid && submitted}" aria-describedby="username-error"/>
                            <label for="username">E-posta</label>
                        </span>
                    </div>

                    <small class="p-error" v-if="(v$.username.required.$invalid && submitted)">Lütfen mail adresi giriniz!</small>
                    <small class="p-error" v-if="(v$.username.email.$invalid && submitted)">Lütfen Geçerli bir mail adresi giriniz!</small>
                    <br>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-lock"></i>
                        </span>
                        <span class="p-float-label">
                            <InputText type="password" id="password" v-model="v$.password.$model" :class="{'p-invalid':v$.password.$invalid && submitted}" aria-describedby="password-error"/>
                            <label for="password">Şifre</label>
                        </span>
                    </div>
                    <small class="p-error" v-if="(v$.password.required.$invalid && submitted)">Lütfen şifre giriniz!</small>
                    <small class="p-error" v-if="(v$.password.minLength.$invalid && submitted)">Şifre en az 6 karakter olmalı!</small>
                </div>

                <div class="flex justify-content-between px-6">
                    <div class="mr-2">
                        <Button class="p-button-info p-mb-6 p-px-3" label="ŞİFREMİ UNUTTUM" @click="OnSifremiUnuttum"></Button>
                    </div>
                    <div>
                        <Button class="login-button p-mb-6 p-px-3" type="submit" label="OTURUM AÇ"></Button>
                    </div>
                </div>
                <br>
                
                <transition-group name="p-message" tag="div">
                    <Message v-for="msg of messages" :severity="msg.severity" :key="msg.id">{{msg.content}}</Message>
                </transition-group>
            </form>
        </div>
    </div>
</div>

</template>

<script>
    import CrmService from "../service/CrmService";
    import { email, required, minLength } from "@vuelidate/validators";
    import { useVuelidate } from "@vuelidate/core";

	export default {
		setup: () => ({ v$: useVuelidate() }),
		data() {
			return {
                crmService: null,
                messages: [],
                count: 0,
                submitted: false,
                username: '',
				password: '',
			}
		},
        created: function () {
            this.crmService = new CrmService();

            debugger;
            if (window.location.protocol === 'http:') {
                //window.location.href = 'https://' + window.location.host + window.location.pathname;
            }
        },
		methods: {
            OnSifremiUnuttum(){
                this.$router.push('/sifremiunuttum');
            },
            async handleSubmit(isFormValid) {
                debugger;
                this.submitted = true;

                if (!isFormValid) {
                    return;
                }
/*
                let loader = this.$loading.show({
					container: this.$refs.mainContainer
				});
*/
                this.crmService.Login(this.username, this.password)
                .then(response => {
                    debugger;
                    if (response) {
                        //loader.hide();
                        if (response.hata == true) {
                            this.addMessages('error', response.hataAciklamasi);
                        }
                        else {
                            debugger;
                            this.$store.commit('SET_PROFILE', response.user);

                            localStorage.setItem('token', response.token + ':' + this.username);
                            
                            this.addMessages('success', 'Giriş başarılı, yönlendiriliyorsunuz...');
                            setTimeout(() => {
                                this.$router.push('/');
                            }, 1000);
                        }
                    }
                })
                .catch(error => {
                    //loader.hide();
                    console.log(error);
                    this.$router.push('/login');
                })
            },
            addMessages(_type, _message) {
                this.messages = [
                    { severity: _type, content: _message, id: this.count++ }
                ]
            },
		},
        validations() {
            return {
                username: { required, email },
                password: { required, minLength: minLength(6) }
            }
        },
	}
</script>

<style scoped>

</style>
