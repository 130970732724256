import { createStore } from "vuex";
import crmService from "../service/CrmService"

export default createStore({
    state: {
        StringMapsData: [],
        profile: {},
        pageHistory: []
    },
    getters: {
        getStringMapByEntityId: (state) => (entityId, attributeName, valueType = 'int') => {
            let data = state.StringMapsData.filter(x => x.ObjectTypeCode === entityId && x.AttributeName === attributeName)
            if (valueType == 'bool') {
                data.forEach(element => {
                    element.AttributeValue = Boolean(element.AttributeValue);
                });
            }
            return data;
        },
        getProfile: (state) => {
            return state.profile;
        },
        getPageHistory: (state) => {
            debugger;
            console.log(state);
            let tempLocalStoragePageHistory = localStorage.getItem('ls_pagehistory');
            if (tempLocalStoragePageHistory == null) {
                tempLocalStoragePageHistory = [];
            }
            else {
                if (tempLocalStoragePageHistory.indexOf("null") >= 0) { //Null değerli
                    tempLocalStoragePageHistory = [];
                }
            }

            if (tempLocalStoragePageHistory.length > 0) {
                tempLocalStoragePageHistory = JSON.parse(tempLocalStoragePageHistory);
            }
            

            return tempLocalStoragePageHistory;
        }
    },
    mutations: {
        SET_STRINGMAP(state, newStringMaps) {
            state.StringMapsData = newStringMaps;
        },
        SET_PROFILE(state, newProfile) {
            state.profile = newProfile;
        },
        SET_PAGEHISTORY(state, new_pageHistory) {
            debugger;
            let tempLocalStoragePageHistory = localStorage.getItem('ls_pagehistory');
            if (tempLocalStoragePageHistory == null) {
                tempLocalStoragePageHistory = [];
            }
            else {
                if (tempLocalStoragePageHistory.indexOf("null") >= 0) { //Null değerli
                    tempLocalStoragePageHistory = [];
                }
            }

            if (tempLocalStoragePageHistory.length > 0) {
                tempLocalStoragePageHistory = JSON.parse(tempLocalStoragePageHistory);

                //Listeden kaldır
                tempLocalStoragePageHistory = tempLocalStoragePageHistory.filter(pg => pg.ID != new_pageHistory.ID);
            }

            //ilk sıraya ekle
            tempLocalStoragePageHistory.splice(0, 0, new_pageHistory);

            //ilk 10 tanesini al
            tempLocalStoragePageHistory.splice(10);
            
            localStorage.setItem('ls_pagehistory', JSON.stringify(tempLocalStoragePageHistory));
            
        },
    },
    actions: {
        async initStringMaps({commit}) {
            let response = await new crmService().getStringMaps();
            commit('SET_STRINGMAP', response.jsonData);
        }
    },
    modules: {}
})