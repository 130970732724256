<template>
    <div :class="divClass">
        <div class="card height-100">
            <div class="card-header">
                <h5>{{ label }}</h5>
            </div>

            <CrmDataTable :baseEntityName="baseEntityName" :options="options" :rows="gosterilecekSatirAdedi" :isHeaderVisible="true" @onRowSelect="onRowSelect" :isNewButtonVisible="options['isNewButtonVisible']" />
        </div>
    </div>
</template>

<script>

export default {
    props: {
        label: {
            type: String,
            required: true
        },
        baseEntityName: {
            type: String,
            required: true
        },
        gosterilecekSatirAdedi: {
            type: String,
            required: true
        },
        genislik: {
            type: String,
            required: true
        },
        options: {
            type: Object,
            required: false
        },
    },
    computed: {
		divClass() {
			debugger;
			return "col-12 lg:col-" + this.genislik;
		},
	},
    methods: {
        onRowSelect(data) {
			let features = 'directories=no,menubar=no,status=no,titlebar=no,toolbar=no';

			let routeData = this.$router.resolve({ name: this.baseEntityName, params: { id: data.entityId } });
			window.open(routeData.href, '_blank', features);
		},
	},
}
</script>


<style lang="scss" scoped>

</style>  
