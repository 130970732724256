<template>
    <label for="item" @contextmenu="onRightClick">{{label}} <span style="color:red" v-if="required">*</span></label>
    <AutoComplete id="item"  @contextmenu="onRightClick" aria-haspopup="true" v-model="selected" :minLength="3" :delay="700" :suggestions="filtered" @complete="searchLookup($event)" @item-select="itemSelect($event)" @clear="itemClear()" :dropdown="true" dropdownMode="blank" field="Name" :forceSelection="true" :autocomplete="off" appendTo="body" :disabled="disabled">
        <template #item="slotProps">
            <div class="country-item">
                <div class="p-ml-2">{{slotProps.item.Name}}</div>
                <small class="p-ml-2" v-if="slotProps.item.SecondName">{{slotProps.item.SecondName}}</small>
            </div>
        </template>
    </AutoComplete>
    <ContextMenu ref="menu" :model="menuItems" />
</template>

<script>
import CrmService from "../service/CrmService";

export default {
    props: {
        label: {
            type: String,
            required: true
        },
        entityName: {
            type: String,
            required: true
        },
        nameField: {
            type: String,
            default: 'new_name'
        },
        secondNameField: {
            type: String
        },
        secondNameFieldType: {
            type: String
        },
        parentFieldName: {
            type: String
        },
        parentId: {
            type: Object
        },
        state: {
            type: Boolean
        },
        disabled: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        }
    },
    created() {
		this.crmService = new CrmService();
	},
    data: function () {
        return {
            selected: null,
            filtered: null,
            menuItems: [
                {
                    label:'Yeni Sayfada Aç',
                    icon:'pi pi-fw pi-external-link',
                    command: () => { this.OnYeniSayfadaAc(); },
                },
                {
                    label:'Aynı Sayfada Aç',
                    icon:'pi pi-fw pi-external-link',
                    command: () => { this.OnAyniSayfadaAc(); },
                }
            ]	
        }
    },
    methods: {
		searchLookup(event) {
            debugger;

            var _parentId = '';
            if (this.parentId) {
                _parentId = this.parentId.Value;
            }
            debugger;
			this.crmService.GetEntityLookup(this.entityName, this.nameField, event.query, this.parentFieldName, _parentId, this.state, this.secondNameField, this.secondNameFieldType)
			.then(data => {
				this.filtered = data.jsonData;
                debugger;
			});
		},
        itemSelect(event) {
            debugger;
            this.$emit('itemSelected', event.value);
		},
        itemClear() {
            debugger;
            this.$emit('itemCleared');
        },
        setDefaultData(data){
            this.selected = data;
        },
        onRightClick(event) {
            this.$refs.menu.show(event);
        },
        OnYeniSayfadaAc() {
            let routeData = this.$router.resolve({name: this.entityName, params: {id: this.selected.Value}});
            window.open(routeData.href, 'lookup', '_blank');
        },
        OnAyniSayfadaAc() {
            let routeData = this.$router.resolve({name: this.entityName, params: {id: this.selected.Value}});
            window.open(routeData.href, '_top');
            //window.location.href = routeData.href;
            //window.location.replace(routeData.href);
        }
	}
}
</script>