<template>
    <label for="item" @contextmenu="onRightClick">{{label}} <span style="color:red" v-if="required">*</span></label>
    <span class="flex">
        <div class="flex-grow-1 flex">
            <AutoComplete id="item"  @contextmenu="onRightClick" aria-haspopup="true" v-model="selected" :minLength="3" :delay="700" :suggestions="filtered" @complete="searchLookup($event)" @item-select="itemSelect($event)" @clear="itemClear()" :dropdown="true" dropdownMode="blank" field="Name" :forceSelection="true" :autocomplete="off" appendTo="body" :disabled="disabled">
                <template #item="slotProps">
                    <div class="country-item">
                        <div class="p-ml-2">{{slotProps.item.Name}}</div>
                        <small class="p-ml-2" v-if="slotProps.item.SecondName">{{slotProps.item.SecondName}}</small>
                    </div>
                </template>
            </AutoComplete>
        </div>
        <div class="flex-none flex">
            <Button icon="pi pi-search" @click="displayPopup = true" :disabled="disabled"  />
        </div>
    </span>
    <ContextMenu ref="menu" :model="menuItems" />

    <Dialog v-model:visible="displayPopup" header="Lütfen bir değer seçiniz" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '85vw'}" :maximizable="true" :modal="true" @show="dialogShow">
        <div class="p-3">
            <CrmDataTable :baseEntityName="entityName" sizeClass="p-datatable" :options="CrmDataTable_options" :isHeaderVisible="true" :isUserViewVisible="true" :isLookupSearch="true" :isSubGrid="true" @onRowSelect="onRowSelect" @onRowSelectedData="onRowSelectedData" />
        </div>
        
        <template #footer>
            <Button v-if="selectedButtonValue != null" :label="selectedButtonName" icon="pi pi-check" @click="buttonSelectItem" />
        </template>
    </Dialog>
</template>

<script>
import CrmService from "../service/CrmService";

export default {
    props: {
        label: {
            type: String,
            required: true
        },
        entityName: {
            type: String,
            required: true
        },
        nameField: {
            type: String,
            default: 'new_name'
        },
        secondNameField: {
            type: String
        },
        secondNameFieldType: {
            type: String
        },
        parentFieldName: {
            type: String
        },
        parentId: {
            type: Object
        },
        state: {
            type: Boolean
        },
        disabled: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },

        CrmDataTable_options: {
            type: Object
        },
    },
    data: function () {
        return {
            selected: null,
            filtered: null,
            displayPopup: false,
            selectedValue: null,
            selectedButtonName: null,
            selectedButtonValue: null,
            menuItems: [
                {
                    label:'Yeni Sayfada Aç',
                    icon:'pi pi-fw pi-external-link',
                    command: () => { this.OnYeniSayfadaAc(); },
                },
                {
                    label:'Aynı Sayfada Aç',
                    icon:'pi pi-fw pi-external-link',
                    command: () => { this.OnAyniSayfadaAc(); },
                }
            ],
        }
    },
    created() {
        debugger;
		this.crmService = new CrmService();
	},
    methods: {
		searchLookup(event) {
            debugger;

            var _parentId = '';
            if (this.parentId) {
                _parentId = this.parentId.Value;
            }
            debugger;
			this.crmService.GetEntityLookup(this.entityName, this.nameField, event.query, this.parentFieldName, _parentId, this.state, this.secondNameField, this.secondNameFieldType)
			.then(data => {
				this.filtered = data.jsonData;
                debugger;
			});
		},
        itemSelect(event) {
            debugger;
            this.$emit('itemSelected', event.value);
		},
        itemClear() {
            debugger;
            this.$emit('itemCleared');
        },
        setDefaultData(data){
            debugger;
            if (data.Name == undefined) {
                data.Name = 'İSİMSİZ';
            }
            this.selected = data;
        },
        onRowSelect(event) {
            debugger;
            //this.$emit('onRowSelect', event.value);
            this.selectedButtonName = event[this.nameField];
            this.selectedButtonValue = event.entityId;
		},
        onRowSelectedData(event) {
            debugger;
            //this.$emit('onRowSelect', event.value);
            if (event[this.nameField] == undefined) {
                this.selectedButtonName = 'İSİMSİZ';
            }
            else {
                this.selectedButtonName = event[this.nameField];
            }
            
            this.selectedButtonValue = event.entityId;
		},
        dialogShow(){
            this.selectedButtonName = null;
            this.selectedButtonValue = null;
        },
        onRightClick(event) {
            this.$refs.menu.show(event);
        },
        OnYeniSayfadaAc() {
            let routeData = this.$router.resolve({name: this.entityName, params: {id: this.selected.Value}});
            window.open(routeData.href, 'lookup', '_blank');
        },
        OnAyniSayfadaAc() {
            let routeData = this.$router.resolve({name: this.entityName, params: {id: this.selected.Value}});
            window.open(routeData.href, '_top');
            //window.location.href = routeData.href;
            //window.location.replace(routeData.href);
        },
        buttonSelectItem() {
            debugger;
            this.selectedValue = { "Name": this.selectedButtonName, "Value": this.selectedButtonValue }
            this.selected = this.selectedValue;
            this.$emit('itemSelected', this.selectedValue);
            this.displayPopup = false;
        },
	}
}
</script>

<style lang="scss" scoped>
    .p-autocomplete{
        flex-grow: 1;
    }
</style>