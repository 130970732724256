import {createRouter, createWebHashHistory} from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'dashboard',
        exact: true,
        component: () => import(/* webpackChunkName: "VeryImportantThings" */ './components/Dashboard.vue'),
        meta: {
            breadcrumb: [{ label: 'Dashboard' }],
        },
    },
    {
        path: '/dashboard_yonetim',
        name: 'dashboard_yonetim',
        exact: true,
        component: () => import('./components/Dashboard_yonetim.vue'),
        meta: {
            breadcrumb: [{ label: 'Dashboard Yönetim' }],
        },
    },
    {
        path: '/dashboard_yonetim1',
        name: 'dashboard_yonetim1',
        exact: true,
        component: () => import('./components/Dashboard_yonetim1.vue'),
        meta: {
            breadcrumb: [{ label: 'Dashboard Yönetim1' }],
        },
    },
    {
        path: '/dashboard_servis',
        name: 'dashboard_servis',
        exact: true,
        component: () => import('./components/Dashboard_servis.vue'),
        meta: {
            breadcrumb: [{ label: 'Dashboard Servis' }],
        },
    },
    {
        path: '/favorites/dashboardanalytics',
        name: 'dashboardanalytics',
        exact: true,
        component: () => import('./components/DashboardAnalytics.vue'),
        meta: {
            breadcrumb: [{ label: 'Dashboard Analytics' }],
        },
    },
    {
        path: '/dashboardsatis',
        name: 'dashboardsatis',
        component: () => import('./components/DashboardSatis.vue'),
        meta: {
            breadcrumb: [{ label: 'Dashboard' }],
        },
    },
    {
        path: '/testlist',
        name: 'testlist',
        component: () => import('./components/testlist.vue'),
        meta: {
            breadcrumb: [{ label: 'testlist' }],
        },
    },
    {
        path: '/advancedfind',
        name: 'advancedfind',
        component: () => import('./components/AdvancedFind.vue'),
        meta: {
            breadcrumb: [{ label: 'Gelişmiş Bul' }],
        },
    },
    {
        path: '/accountlist',
        name: 'accountlist',
        component: () => import(/* webpackChunkName: "VeryImportantThings" */ './components/AccountList.vue'),
        meta: {
            checkPermissionName: 'Firmalar',
            breadcrumb: [{ label: 'Firmalar' }],
        },
    },
    {
        path: '/account/:id',
        name: 'account',
        component: () => import(/* webpackChunkName: "VeryImportantThings" */ './components/Account.vue'),
        meta: {
            checkPermissionName: 'Firmalar',
            breadcrumb: [{ parent: 'Firmalar', label: 'Firma Detay' }],
        },
    },
    {
        path: '/accountnew',
        name: 'accountnew',
        checkPermissionName: 'Firmalar',
        component: () => import('./components/AccountNew.vue'),
        meta: {
            breadcrumb: [{ label: 'Yeni Firma' }],
        },
    },
    {
        path: '/appointmentlist',
        name: 'appointmentlist',
        component: () => import('./components/AppointmentList.vue'),
        meta: {
            breadcrumb: [{ label: 'Randevular' }],
        },
    },
    {
        path: '/appointment/:id',
        name: 'appointment',
        component: () => import('./components/Appointment.vue'),
        meta: {
            breadcrumb: [{ label: 'Randevu' }],
        },
    },
    {
        path: '/appointmentnew/:id/:name',
        name: 'appointmentnew',
        component: () => import('./components/AppointmentNew.vue'),
        meta: {
            breadcrumb: [{ label: 'Randevu' }],
        },
    },
    {
        path: '/appointmentnew/:id/:name/:opportunityId',
        name: 'appointmentnew_withopportunity',
        component: () => import('./components/AppointmentNew.vue'),
        meta: {
            breadcrumb: [{ label: 'Randevu' }],
        },
    },
    {
        path: '/phonecalllist',
        name: 'phonecalllist',
        component: () => import('./components/PhonecallList.vue'),
        meta: {
            breadcrumb: [{ label: 'Telefon Görüşmeleri' }],
        },
    },
    {
        path: '/phonecall/:id',
        name: 'phonecall',
        component: () => import('./components/Phonecall.vue'),
        meta: {
            breadcrumb: [{ label: 'Telefon Görüşmesi' }],
        },
    },
    {
        path: '/phonecallnew/:id/:name',
        name: 'phonecallnew',
        component: () => import('./components/PhonecallNew.vue'),
        meta: {
            breadcrumb: [{ label: 'Telefon Görüşmesi' }],
        },
    },
    {
        path: '/phonecallnew/:id/:name/:opportunityId',
        name: 'phonecallnew_withopportunity',
        component: () => import('./components/PhonecallNew.vue'),
        meta: {
            breadcrumb: [{ label: 'Telefon Görüşmesi' }],
        },
    },
    {
        path: '/contactlist',
        name: 'contactlist',
        component: () => import('./components/ContactList.vue'),
        meta: {
            breadcrumb: [{ label: 'İlgili Kişiler' }],
        },
    },
    {
        path: '/contact/:id',
        name: 'contact',
        component: () => import('./components/Contact.vue'),
        meta: {
            breadcrumb: [{ parent: 'İlgili Kişiler', label: 'İlgili Kişi' }],
        },
    },
    {
        path: '/contactnew',
        name: 'contactnew',
        component: () => import('./components/ContactNew.vue'),
        meta: {
            breadcrumb: [{ parent: 'İlgili Kişiler', label: 'İlgili Kişi' }],
        },
    },
    {
        path: '/contactnewfirma/:id',
        name: 'contactnewfirma',
        component: () => import('./components/ContactNew.vue'),
        meta: {
            breadcrumb: [{ parent: 'İlgili Kişiler', label: 'İlgili Kişi' }],
        },
    },
    {
        path: '/bm_hedefayrintilist',
        name: 'bm_hedefayrintilist',
        component: () => import('./components/bm_hedefayrintiList.vue'),
        meta: {
            breadcrumb: [{ label: 'Hedefler' }],
        },
    },
    {
        path: '/bm_unitelist',
        name: 'bm_unitelist',
        component: () => import('./components/bm_uniteList.vue'),
        meta: {
            breadcrumb: [{ label: 'Tezgahlar' }],
        },
    },
    {
        path: '/bm_unite/:id',
        name: 'bm_unite',
        component: () => import('./components/bm_unite.vue'),
        meta: {
            breadcrumb: [{ parent: 'Tezgahlar', label: 'Tezgah' }],
        },
    },
    {
        path: '/bm_unitenew',
        name: 'bm_unitenew',
        component: () => import('./components/bm_uniteNew.vue'),
        meta: {
            breadcrumb: [{ parent: 'Tezgahlar', label: 'Tezgah Yeni' }],
        },
    },
    {
        path: '/opportunitynew',
        name: 'opportunitynew',
        component: () => import('./components/OpportunityNew.vue'),
        meta: {
            breadcrumb: [{ label: 'Satış Projesi Yeni' }],
        },
    },
    {
        path: '/opportunitynewfirma/:id',
        name: 'opportunitynewfirma',
        component: () => import('./components/OpportunityNew.vue'),
        meta: {
            breadcrumb: [{ label: 'Satış Projesi Yeni' }],
        },
    },
    {
        path: '/opportunitylist',
        name: 'opportunitylist',
        component: () => import('./components/OpportunityList.vue'),
        meta: {
            breadcrumb: [{ label: 'Satış Projeleri' }],
        },
    },
    {
        path: '/opportunity/:id',
        name: 'opportunity',
        component: () => import('./components/Opportunity.vue'),
        meta: {
            breadcrumb: [{ label: 'Satış Proje' }],
        },
    },
    {
        path: '/quotelist',
        name: 'quotelist',
        component: () => import('./components/QuoteList.vue'),
        meta: {
            breadcrumb: [{ label: 'Teklifler' }],
        },
    },
    {
        path: '/quotenew/:opportunityid',
        name: 'quotenew_withopportunityid',
        component: () => import('./components/ServiceAppointmentNew.vue'),
        meta: {
            breadcrumb: [{ label: 'Teklif' }],
        },
    },
    {
        path: '/quote/:id',
        name: 'quote',
        component: () => import('./components/Quote.vue'),
        meta: {
            breadcrumb: [{ label: 'Teklif' }],
        },
    },
    {
        path: '/quotetezgahekle/:quoteId',
        name: 'quotetezgahekle',
        component: () => import('./components/Quote_TezgahEkle.vue'),
        meta: {
            breadcrumb: [{  parent: 'Teklif', label: 'Tezgah Ekle' }],
        },
    },
    {
        path: '/quotetezgahekle2/:quoteId',
        name: 'quotetezgahekle2',
        component: () => import('./components/Quote_TezgahEkle2.vue'),
        meta: {
            breadcrumb: [{  parent: 'Teklif', label: 'Tezgah Ekle' }],
        },
    },
    {
        path: '/salesorderlist',
        name: 'salesorderlist',
        component: () => import('./components/SalesOrderList.vue'),
        meta: {
            breadcrumb: [{ label: 'Siparişler' }],
        },
    },
    {
        path: '/salesorder/:id',
        name: 'salesorder',
        component: () => import('./components/SalesOrder.vue'),
        meta: {
            breadcrumb: [{ label: 'Sipariş' }],
        },
    },
    {
        path: '/productlist',
        name: 'productlist',
        component: () => import('./components/ProductList.vue'),
        meta: {
            breadcrumb: [{ label: 'Ürünler' }],
        },
    },
    {
        path: '/product/:id',
        name: 'product',
        component: () => import('./components/Product.vue'),
        meta: {
            breadcrumb: [{ label: 'Ürün' }],
        },
    },
    {
        path: '/bm_yedekparcalist',
        name: 'bm_yedekparcalist',
        component: () => import('./components/bm_yedekparcaList.vue'),
        meta: {
            breadcrumb: [{ label: 'Yedek Parça' }],
        },
    },
    {
        path: '/bm_yedekparca/:id',
        name: 'bm_yedekparca',
        component: () => import('./components/bm_yedekparca.vue'),
        meta: {
            breadcrumb: [{ label: 'Yedek Parça' }],
        },
    },
    {
        path: '/bm_yedekparcanew',
        name: 'bm_yedekparcanew',
        component: () => import('./components/bm_yedekparcanew.vue'),
        meta: {
            breadcrumb: [{ label: 'Yeni Yedek Parça' }],
        },
    },
    {
        path: '/bm_yedekparcateklifsiparislist',
        name: 'bm_yedekparcateklifsiparislist',
        component: () => import('./components/bm_yedekparcateklifsiparisList.vue'),
        meta: {
            breadcrumb: [{ label: 'Yedek Parça Teklif Sipariş' }],
        },
    },
    {
        path: '/bm_yedekparcateklifsiparis/:id',
        name: 'bm_yedekparcateklifsiparis',
        component: () => import('./components/bm_yedekparcateklifsiparis.vue'),
        meta: {
            breadcrumb: [{ label: 'Yedek Parça Teklif/Sipariş' }],
        },
    },
    {
        path: '/bm_yedekparcateklifsiparisnew',
        name: 'bm_yedekparcateklifsiparisnew',
        component: () => import('./components/bm_yedekparcateklifsiparisNew.vue'),
        meta: {
            breadcrumb: [{ label: 'Yeni Yedek Parça Teklif Sipariş' }],
        },
    },
    {
        path: '/equipment/:id',
        name: 'equipment',
        component: () => import('./components/equipment.vue'),
        meta: {
            breadcrumb: [{ label: 'Tesis / Ekipman' }],
        },
    },
    {
        path: '/equipmentnew',
        name: 'equipmentnew',
        component: () => import('./components/equipmentNew.vue'),
        meta: {
            breadcrumb: [{ label: 'Tesis / Ekipman' }],
        },
    },
    {
        path: '/equipmentlist',
        name: 'equipmentlist',
        component: () => import('./components/equipmentList.vue'),
        meta: {
            breadcrumb: [{ label: 'Tesis / Ekipman' }],
        },
    },
    {
        path: '/bm_sozlesmelerlist',
        name: 'bm_sozlesmelerlist',
        component: () => import('./components/bm_sozlesmelerList.vue'),
        meta: {
            breadcrumb: [{ label: 'Sözleşmeler' }],
        },
    },
    {
        path: '/bm_sozlesmeler/:id',
        name: 'bm_sozlesmeler',
        component: () => import('./components/bm_sozlesmeler.vue'),
        meta: {
            breadcrumb: [{ label: 'Sözleşmeler' }],
        },
    },
    {
        path: '/bm_sozlesmelernew',
        name: 'bm_sozlesmelernew',
        component: () => import('./components/bm_sozlesmelerNew.vue'),
        meta: {
            breadcrumb: [{ label: 'Sözleşmeler Yeni' }],
        },
    },
    {
        path: '/bm_sozlesmesatirinew/:sozlesmeId/:sozlesmeName',
        name: 'bm_sozlesmesatirinew',
        component: () => import('./components/bm_sozlesmesatiriNew.vue'),
        meta: {
            breadcrumb: [{ label: 'Yeni Sözleşme Satırı' }],
        },
    },
    {
        path: '/bm_sozlesmesatiri/:id',
        name: 'bm_sozlesmesatiri',
        component: () => import('./components/bm_sozlesmesatiri.vue'),
        meta: {
            breadcrumb: [{ label: 'Sözleşme Satırı' }],
        },
    },
    {
        path: '/incidentlist',
        name: 'incidentlist',
        component: () => import('./components/IncidentList.vue'),
        meta: {
            breadcrumb: [{ label: 'Servis Talepleri' }],
        },
    },
    {
        path: '/incident/:id',
        name: 'incident',
        component: () => import('./components/Incident.vue'),
        meta: {
            breadcrumb: [{ label: 'Servis Talebi' }],
        },
    },
    {
        path: '/incidentnew',
        name: 'incidentnew',
        component: () => import('./components/IncidentNew.vue'),
        meta: {
            breadcrumb: [{ label: 'Servis Talebi Yeni' }],
        },
    },
    {
        path: '/incidentnew_unite/:uniteId',
        name: 'incidentnew_unite',
        component: () => import('./components/IncidentNew.vue'),
        meta: {
            breadcrumb: [{ label: 'Servis Talebi Yeni' }],
        },
    },
    {
        path: '/serviceappointmentlist',
        name: 'serviceappointmentlist',
        component: () => import('./components/ServiceAppointmentList.vue'),
        meta: {
            breadcrumb: [{ label: 'Servis Raporları' }],
        },
    },
    {
        path: '/serviceappointment/:id',
        name: 'serviceappointment',
        component: () => import('./components/ServiceAppointment.vue'),
        meta: {
            breadcrumb: [{ label: 'Servis Raporu' }],
        },
    },
    {
        path: '/serviceappointmentnew/:incidentid',
        name: 'serviceappointment_withIncident',
        component: () => import('./components/ServiceAppointmentNew.vue'),
        meta: {
            breadcrumb: [{ label: 'Servis Raporu' }],
        },
    },
    {
        path: '/bm_rakipunite/:id',
        name: 'bm_rakipunite',
        component: () => import('./components/bm_rakipunite.vue'),
        meta: {
            breadcrumb: [{ label: 'Rakip Ünite' }],
        },
    },
    {
        path: '/bm_rakipunitenew/:accountid',
        name: 'bm_rakipunite_withAccountId',
        component: () => import('./components/bm_rakipuniteNew.vue'),
        meta: {
            breadcrumb: [{ label: 'Rakip Ünite' }],
        },
    },
    {
        path: '/bm_otomasyonprojesilist',
        name: 'bm_otomasyonprojesilist',
        component: () => import('./components/bm_otomasyonprojesiList.vue'),
        meta: {
            breadcrumb: [{ label: 'Otomasyon Projesi' }],
        },
    },
    {
        path: '/bm_otomasyonprojesinew',
        name: 'bm_otomasyonprojesinew',
        component: () => import('./components/bm_otomasyonprojesiNew.vue'),
        meta: {
            breadcrumb: [{ label: 'Otomasyon Projesi' }],
        },
    },
    {
        path: '/bm_otomasyonprojesi/:id',
        name: 'bm_otomasyonprojesi',
        component: () => import('./components/bm_otomasyonprojesi.vue'),
        meta: {
            breadcrumb: [{ label: 'Otomasyon Projesi' }],
        },
    },
    {
        path: '/bm_otomasyonteklifnew/:id',
        name: 'bm_otomasyonteklifnew',
        component: () => import('./components/bm_otomasyonteklifNew.vue'),
        meta: {
            breadcrumb: [{ label: 'Otomasyon Teklif' }],
        },
    },
    {
        path: '/bm_otomasyonteklif/:id',
        name: 'bm_otomasyonteklif',
        component: () => import('./components/bm_otomasyonteklif.vue'),
        meta: {
            breadcrumb: [{ label: 'Otomasyon Teklif' }],
        },
    },
    {
        path: '/bm_otomasyonsatis/:id',
        name: 'bm_otomasyonsatis',
        component: () => import('./components/bm_otomasyonsatis.vue'),
        meta: {
            breadcrumb: [{ label: 'Otomasyon Satış' }],
        },
    },
    {
        path: '/bm_otomasyontasarimnew/:id',
        name: 'bm_otomasyontasarimnew',
        component: () => import('./components/bm_otomasyontasarimNew.vue'),
        meta: {
            breadcrumb: [{ label: 'Otomasyon Tasarım' }],
        },
    },
    {
        path: '/bm_otomasyontasarim/:id',
        name: 'bm_otomasyontasarim',
        component: () => import('./components/bm_otomasyontasarim.vue'),
        meta: {
            breadcrumb: [{ label: 'Otomasyon Tasarım' }],
        },
    },
    {
        path: '/bm_otomasyonimalatnew/:id',
        name: 'bm_otomasyonimalatnew',
        component: () => import('./components/bm_otomasyonimalatNew.vue'),
        meta: {
            breadcrumb: [{ label: 'Otomasyon İmalat Yeni' }],
        },
    },
    {
        path: '/bm_otomasyonimalat/:id',
        name: 'bm_otomasyonimalat',
        component: () => import('./components/bm_otomasyonimalat.vue'),
        meta: {
            breadcrumb: [{ label: 'Otomasyon İmalat' }],
        },
    },
    {
        path: '/bm_otomasyonekipmantalebinew/:id',
        name: 'bm_otomasyonekipmantalebinew',
        component: () => import('./components/bm_otomasyonekipmantalebiNew.vue'),
        meta: {
            breadcrumb: [{ label: 'Otomasyon Ekipman Talebi Yeni' }],
        },
    },
    {
        path: '/bm_otomasyonekipmantalebi/:id',
        name: 'bm_otomasyonekipmantalebi',
        component: () => import('./components/bm_otomasyonekipmantalebi.vue'),
        meta: {
            breadcrumb: [{ label: 'Otomasyon Ekipman Talebi' }],
        },
    },
    {
        path: '/bm_otomasyonsatinalmanew/:id',
        name: 'bm_otomasyonsatinalmanew',
        component: () => import('./components/bm_otomasyonsatinalmaNew.vue'),
        meta: {
            breadcrumb: [{ label: 'Otomasyon Satın Alma Yeni' }],
        },
    },
    {
        path: '/bm_otomasyonsatinalma/:id',
        name: 'bm_otomasyonsatinalma',
        component: () => import('./components/bm_otomasyonsatinalma.vue'),
        meta: {
            breadcrumb: [{ label: 'Otomasyon Satın Alma' }],
        },
    },
    {
        path: '/bm_otomasyonkurulumnew/:id',
        name: 'bm_otomasyonkurulumnew',
        component: () => import('./components/bm_otomasyonkurulumNew.vue'),
        meta: {
            breadcrumb: [{ label: 'Otomasyon Kurulum Yeni' }],
        },
    },
    {
        path: '/bm_otomasyonkurulum/:id',
        name: 'bm_otomasyonkurulum',
        component: () => import('./components/bm_otomasyonkurulum.vue'),
        meta: {
            breadcrumb: [{ label: 'Otomasyon Kurulum' }],
        },
    },
    {
        path: '/bm_otomasyonservislist',
        name: 'bm_otomasyonservislist',
        component: () => import('./components/bm_otomasyonservisList.vue'),
        meta: {
            breadcrumb: [{ label: 'Otomasyon Servis' }],
        },
    },
    {
        path: '/bm_otomasyonservisnew',
        name: 'bm_otomasyonservisnew',
        component: () => import('./components/bm_otomasyonservisNew.vue'),
        meta: {
            breadcrumb: [{ label: 'Otomasyon Servis Yeni' }],
        },
    },
    {
        path: '/bm_otomasyonservis/:id',
        name: 'bm_otomasyonservis',
        component: () => import('./components/bm_otomasyonservis.vue'),
        meta: {
            breadcrumb: [{ label: 'Otomasyon Servis' }],
        },
    },
    {
        path: '/sifredegistir',
        name: 'sifredegistir',
        component: () => import('./components/SifreDegistir.vue'),
        meta: {
            breadcrumb: [{ label: 'Şifre Değiştir' }],
        },
    },
    {
        path: '/printgrid/:baseEntityName/:savedQueryId/:savedQueryName',
        name: 'printgrid',
        component: () => import('./pages/PrintGrid.vue'),
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'Yazdır' }],
        },
    },
    {
        path: '/test',
        name: 'test',
        component: () => import('./components/Test.vue'),
        meta: {
            breadcrumb: [{ label: 'Test' }],
        },
    },
    {
        path: '/toolbox',
        name: 'toolbox',
        component: () => import('./components/Toolbox.vue'),
        meta: {
            breadcrumb: [{ label: 'Toolbox' }],
        },
    },
    {
        path: '/formlayout',
        name: 'formlayout',
        component: () => import('./components/FormLayoutDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Form Layout' }],
        },
    },
    {
        path: '/invalidstate',
        name: 'invalidstate',
        component: () => import('./components/InvalidStateDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Invalid State' }],
        },
    },
    {
        path: '/input',
        name: 'input',
        component: () => import('./components/InputDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Input' }],
        },
    },
    {
        path: '/floatlabel',
        name: 'floatlabel',
        component: () => import('./components/FloatLabelDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Float Label' }],
        },
    },
    {
        path: '/button',
        name: 'button',
        component: () => import('./components/ButtonDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Button' }],
        },
    },
    {
        path: '/table',
        name: 'table',
        component: () => import('./components/TableDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Table' }],
        },
    },
    {
        path: '/list',
        name: 'list',
        component: () => import('./components/ListDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'List' }],
        },
    },
    {
        path: '/timeline',
        name: 'timeline',
        component: () => import('./pages/TimelineDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'Timeline' }],
        },
    },
    {
        path: '/tree',
        name: 'tree',
        component: () => import('./components/TreeDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Tree' }],
        },
    },
    {
        path: '/overlay',
        name: 'overlay',
        component: () => import('./components/OverlaysDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Overlay' }],
        },
    },
    {
        path: '/media',
        name: 'media',
        component: () => import('./components/MediaDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Media' }],
        },
    },
    {
        path: '/menus',
        component: () => import('./components/MenusDemo.vue'),
        children: [
            {
                path: '',
                component: () => import('./components/menu/PersonalDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'Menu' }],
                },
            },
            {
                path: '/menus/seat',
                component: () => import('./components/menu/SeatDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'Menu' }],
                },
            },
            {
                path: '/menus/payment',
                component: () => import('./components/menu/PaymentDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'Menu' }],
                },
            },
            {
                path: '/menus/confirmation',
                component: () => import('./components/menu/ConfirmationDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'Menu' }],
                },
            },
        ],
    },
    {
        path: '/messages',
        name: 'messages',
        component: () => import('./components/MessagesDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Messages' }],
        },
    },
    {
        path: '/file',
        name: 'file',
        component: () => import('./components/FileDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'File' }],
        },
    },
    {
        path: '/chart',
        name: 'chart',
        component: () => import('./components/ChartsDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Charts' }],
        },
    },
    {
        path: '/misc',
        name: 'misc',
        component: () => import('./components/MiscDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Misc' }],
        },
    },
    {
        path: '/icons',
        name: 'icons',
        component: () => import('./utilities/Icons.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Icons' }],
        },
    },
    {
        path: '/widgets',
        name: 'widgets',
        component: () => import('./utilities/Widgets.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Widgets' }],
        },
    },
    {
        path: '/grid',
        name: 'grid',
        component: () => import('./utilities/GridDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Grid System' }],
        },
    },
    {
        path: '/spacing',
        name: 'spacing',
        component: () => import('./utilities/SpacingDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Spacing' }],
        },
    },
    {
        path: '/elevation',
        name: 'elevation',
        component: () => import('./utilities/ElevationDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Elevation' }],
        },
    },
    {
        path: '/typography',
        name: 'typography',
        component: () => import('./utilities/Typography.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Typography' }],
        },
    },
    {
        path: '/display',
        name: 'display',
        component: () => import('./utilities/DisplayDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Display' }],
        },
    },
    {
        path: '/flexbox',
        name: 'flexbox',
        component: () => import('./utilities/FlexBoxDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Flexbox' }],
        },
    },
    {
        path: '/text',
        name: 'text',
        component: () => import('./utilities/TextDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Text' }],
        },
    },
    {
        path: '/crud',
        name: 'crud',
        component: () => import('./pages/CrudDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'Crud' }],
        },
    },
    {
        path: '/calendar',
        name: 'calendar',
        component: () => import('./pages/CalendarDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'Calendar' }],
        },
    },
    {
        path: '/invoice',
        name: 'invoice',
        component: () => import('./pages/Invoice.vue'),
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'Invoice' }],
        },
    },
    {
        path: '/help',
        name: 'help',
        component: () => import('./pages/Help.vue'),
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'Help' }],
        },
    },
    {
        path: '/empty',
        name: 'empty',
        component: () => import('./components/EmptyPage.vue'),
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'Empty Page' }],
        },
    },
    {
        path: '/documentation',
        name: 'documentation',
        component: () => import('./components/Documentation.vue'),
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'Documentation' }],
        },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue')
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue')
    },
    {
        path: '/landing',
        name: 'landing',
        component: () => import('./pages/Landing.vue')
    },
    {
        path: '/contactus',
        name: 'contactus',
        component: () => import('./pages/ContactUs.vue')
    },
    {
        path: '/blocks',
        name: 'blocks',
        component: () => import('./components/BlocksDemo.vue')
    },
    {
        path: '/accessdenied',
        name: 'accessdenied',
        component: () => import('./components/AccessDenied.vue')
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior () {
        return { left: 0, top: 0 };
    }
});

/*
router.beforeEach((to, from, next) => {
    console.log(to.meta.checkPermissionName);
    debugger;
    if (to.meta.checkPermissionName != undefined) {
        if (user.checkPermissionModul(to.meta.checkPermissionName)) {
            next();
        }
        else {
            debugger;
            console.log(to);
            if (to.name != 'accessdenied') {
                next({ name: 'accessdenied' });
            }
            else {
                next();
            }
        }
    }
    else {
        next();
    }
  })
*/
export default router;