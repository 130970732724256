<template>
	<div class="layout-footer flex p-4 pl-5 shadow-2">
		<div class="mr-3 text-red-600">
			<span class="pi pi-history font-bold text-4xl" style="color:#4caf50" ></span>
		</div>
		<div v-for="(page, index) in pageHistoryData" :key="index">
			<Button :label="page.entityLetter" class="p-button text-2xl p-1 mr-3" :class="getColorFromEntityName(page.entityName)" v-tooltip.top="page.title" @click="GoToPage(page)"  />
		</div>
		

		<img id="footer-logo" src="layout/images/logo_renkli.png" alt="ultima-footer-logo" class="ml-auto" style="height:30px" />
	</div>
</template>

<script>
	export default {
		name: "AppFooter",
		props: {
			layoutMode: {
				type: String,
				default: 'light'
			}
		},
		data() {
			return {

			}
		},
		created() {
			console.log('footer created');
		},
		mounted() {
			console.log('footer mounted');
		},
		methods: {
			getColorFromEntityName(entityName) {
				switch (entityName) {
					case 'account':
						return 'bg-blue-500';
					case 'contact':
						return 'bg-blue-700';
					case 'opportunity':
						return 'bg-yellow-500';
					case 'salesorder':
						return 'bg-green-500';
					case 'incident':
						return 'bg-purple-500';
					case 'serviceappointment':
						return 'bg-purple-800';
					case 'product':
						return 'bg-pink-400';
					case 'bm_unite':
						return 'bg-pink-600';
					default:
						break;
				}
			},
			GoToPage(page){
				let features = 'directories=no,menubar=no,status=no,titlebar=no,toolbar=no';

				let routeData = this.$router.resolve({ name: page.entityName, params: { id: page.ID } });
				window.open(routeData.href, '_blank', features);
			},
		},
		computed: {
			isRTL() {
				return this.$appState.RTL;
			},
			pageHistoryData() {
				return this.$store.getters.getPageHistory;
			},
		}
	}
</script>

<style scoped>

</style>