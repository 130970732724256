<template>
    <label>{{label}} <span style="color:red" v-if="required">*</span></label>
    <span class="flex">
        <div class="flex-grow-1 flex">
            <TreeSelect :key="treeSelect_key" v-model="selectedValue" :options="nodes.jsonData" placeholder="Seçiniz" @node-select="NodeSelect($event)" :disabled="disabled"></TreeSelect>
        </div>
        <div class="flex-none flex">
            <Button icon="pi pi-search" @click="displayPopup = true" :disabled="disabled"  />
        </div>
    </span>
    <Dialog v-model:visible="displayPopup" header="Lütfen bir değer seçiniz" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :maximizable="true" :modal="true" @show="dialogShow">
        <div class="p-3">
            <div class="flex flex-wrap gap-2 mb-4">
                <Button type="button" icon="pi pi-plus" label="Tümünü Genişlet" @click="expandAll" />
                <Button type="button" icon="pi pi-minus" label="Tümünü Daralt" @click="collapseAll" class="ml-3" />
            </div>
            <Tree selectionMode="single" :expandedKeys="expandedKeys" v-model:selectionKeys="selectedValueTemp" :value="nodes.jsonData" @nodeSelect="NodeSelectTree" :filter="true" filterMode="strict" class="w-full"></Tree>
        </div>
        <template #footer>
            <h5 v-if="getKeysCount == false">Lütfen bir kayıt seçiniz</h5>
            <Button v-if="getKeysCount == true" label="Seç" icon="pi pi-check" @click="onSelect"/>
        </template>
    </Dialog>
</template>

<script>
import CrmService from "../service/CrmService";

export default {
    props: {
        label: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
    },
    data: function () {
        return {
            nodes: [],
            treeSelect_key: 0,
            displayPopup: false,
            selectedValue: {},
            selectedValueTemp: {},
            mainData: null,
            expandedKeys:   {},
            expandedKeys_tumuAcik:   {
                "bf62376e-c60b-dd11-ae05-000e0c3f151f": true,
                "816ae358-0531-e811-b78c-e41f136a9174": true,
                "9ba010d4-7eee-dc11-b58e-000e0c3f151f": true,
                "96dce5b5-5880-e811-9289-e41f136a9174": true,
                "501d2f5a-c70b-dd11-ae05-000e0c3f151f": true,
                "99ead3ff-7eee-dc11-b58e-000e0c3f151f": true,
                "25bf8d53-7fee-dc11-b58e-000e0c3f151f": true,
                "4fe0b828-7fee-dc11-b58e-000e0c3f151f": true,
                "51ed9b13-7fee-dc11-b58e-000e0c3f151f": true,
                "8700ad40-7fee-dc11-b58e-000e0c3f151f": true,
                "6f34ee1f-7fee-dc11-b58e-000e0c3f151f": true,
                "1cadf8ff-230a-dd11-8567-000e0c3f151f": true,
                "7d42eb2b-2415-dd11-ad90-000e0c3f151f": true,
                "6d341565-2415-dd11-ad90-000e0c3f151f": true,
                "75937027-bc8e-e811-85f0-e41f136a9174": true,
                "3d1febff-d2af-e111-8d3b-000e0c3f151f": true,
                "4fd5f124-240a-dd11-8567-000e0c3f151f": true,
                "c67a3f7a-6228-e411-bd21-e41f136a9174": true
            },
            isSelected: false
        }
    },
    created() {
        debugger;
		this.crmService = new CrmService();
        this.OnLoad();
	},
    computed: {
		getKeysCount() {
            if (this.isSelected == false) {
                return false;
            }
            else {
                return Object.keys(this.selectedValueTemp).length === 1;
            }
		},
    },
    methods: {
        async OnLoad(){
			try {
                this.nodes = await this.crmService.GetSubjects();
            } catch (error) {
                console.log(error);
            }
		},
        NodeSelect(event) {
            debugger;
			this.selectedValue = event.key;
			this.$emit('itemSelected', event.key);
            debugger;
		},
        NodeSelectTree(event) {debugger;
            console.log(event);
			this.selectedValueTemp = event.key;
            this.isSelected = true;
		},
        onSelect(){
            this.selectedValue = this.selectedValueTemp;
            this.$emit('itemSelected', Object.keys(this.selectedValue)[0]);
            this.displayPopup = false;
        },
        setDefaultData(data){
            debugger;
            this.selectedValue = data;
        },
        expandAll() {
            for (let node of this.nodes.jsonData) {
                this.expandNode(node);
            }

            this.expandedKeys = { ...this.expandedKeys };
        },
        collapseAll() {
            this.expandedKeys = {};
        },
        expandNode(node) {
            if (node.children && node.children.length) {
                this.expandedKeys[node.key] = true;

                for (let child of node.children) {
                    this.expandNode(child);
                }
            }
        }
	}
}
</script>

<style lang="scss" scoped>
    .p-treeselect {
        flex-grow: 1;
    }
</style>