<template>
    <div class="card p-fluid">
        <div class="flex justify-content-between flex-wrap">
            <h5>GÖRSEL</h5>
            <div style="width: 140px;">
                <Button v-if="imageUrl == null" icon="pi pi-upload" class="p-button-success" label="Görsel Yükle" @click="displayDosyaYukle=true" />
            </div>
            <div style="width: 100px;">
                <Button v-if="imageUrl != null" icon="pi pi-trash" class="p-button-danger" label="Sil" @click="deleteFile" />
            </div>
        </div>

        <Image v-if="imageUrl != null" :key="image_key" :src="imageUrl" alt="Image" width="250" preview />
    </div>

    <Dialog v-model:visible="displayDosyaYukle" :breakpoints="{'960px': '90vw', '640px': '90vw'}" :style="{width: '50vw'}" header="Dosya Yükle" :modal="true" :closable="true" :showHeader="true" :draggable="true">
        <br>
        <div class="p-2">
            <div class="grid">
                <div class="col-12">
                    <div class="p-field">
                        <FileUpload ref="fileUpload" :key="fileUploadTemp" :customUpload="true" @uploader="fileUploader" :multiple="false" accept="image/*" :maxFileSize="10000000" :invalidFileSizeMessage="'{0}: dosya boyutu limitini aştınız, {1} daha küçük bir dosya yükleyiniz.'" :fileLimit="1" >
                            <template #empty>
                                <p>Yüklenecek dosyayı buraya taşıyınız</p>
                            </template>
                        </FileUpload>
                    </div>
                </div>
            </div>
        </div>
    </Dialog>
</template>
<script>

    import CrmService from "../service/CrmService";
    export default {
        props: {
            logicalName: {
                type: String,
                required: true
            },
            objectId: {
                type: String,
                required: true
            },
            fileName: {
                type: String,
                required: true
            },
        },
        data() {
            return {
                crmService: null,
                fileUploadTemp: 0,
                displayDosyaYukle: false,
                imageUrl: null,
                image_key: 0,
                fileNameTemp: null
            }
        },
        async created() {
            this.crmService = new CrmService();
            this.OnLoad();
        },
        methods: {
            async OnLoad(){
                try {
                    debugger;
                    if (this.imageUrl == null) {
                        this.fileNameTemp = this.fileName;
                        let fileNameTemp2 = this.fileNameTemp.replace(".", "--");
                        fileNameTemp2 = fileNameTemp2.replace("\\", "||");
                        this.imageUrl = process.env.VUE_APP_SERVICEURL + "/global/GetUploadFile/" + fileNameTemp2 + "?time=" + this.image_key;
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            fileUploader(event){
                let loader = this.$loading.show({
                    container: this.$refs.mainContainer
                });

                let postData = {
                    logicalName : this.logicalName,
                    objectId: this.objectId
                };

                var formData = new FormData();
                if (event.files.length > 0) {

                    for( var i = 0; i < event.files.length; i++ ){
                        let file = event.files[i];
                        formData.append('files[' + i + ']', file);
                    }
                }

                this.crmService.CreateUploadFile(formData, postData)
                .then(response => {
                    this.fileUploadTemp++;
                    loader.hide();

                    if (response.hata == false) {
                        this.$toast.add({severity:'success', summary: 'Dosya Yükleme', detail:'Başarıyla Kaydedildi', life: 5000});
                        this.$refs.fileUpload.clear();
                        this.displayDosyaYukle = false;
                        const dosyaAdiParcalari = event.files[0].name.split(".");
                        const dosyaUzantisi = dosyaAdiParcalari[dosyaAdiParcalari.length - 1];

                        this.imageUrl = process.env.VUE_APP_SERVICEURL + "/global/GetUploadFile/" + postData.logicalName + "||" + postData.objectId + "--" + dosyaUzantisi + "?time=" + this.image_key;
                        this.fileNameTemp = postData.logicalName + "//" + postData.objectId + "." + dosyaUzantisi;
                        this.image_key++;
                        debugger;
                        //this.OnLoad();
                    }
                    else {
                        this.$toast.add({severity:'error', summary: 'Dosya Yüklenemedi!', detail: response.hataAciklamasi, life: 5000});
                    }
                })
                .catch(error => {
                    loader.hide();
                    this.$toast.add({severity:'error', summary: 'Dosya Yüklenemedi!', detail: error, life: 5000});
                })
            },
            async deleteFile(){
                this.$confirm.require({
                    message: 'Görsel silinecek emin misiniz?',
                    header: 'Silme Onayı',
                    icon: 'pi pi-exclamation-triangle',
                    acceptClass: 'p-button-danger',
                    acceptLabel: 'Evet',
                    rejectLabel: 'Hayır',
                    accept: async () => {                        
                        let loader = this.$loading.show({
                            container: this.$refs.smsContainer
                        });

                        try {
                            const response = await this.crmService.DeleteUploadFile(this.fileNameTemp);
                            debugger;
                            if (response) {
                                if (response.hata == true) {
                                    this.$toast.add({severity:'error', summary: 'Silinemedi', detail: response.hataAciklamasi, life: 5000});
                                }else {
                                    loader.hide();
                                    setTimeout(() => {
                                        debugger;
                                        this.OnLoad();
                                        this.imageUrl = null;
                                        this.fileNameTemp = null;

                                        this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla silindi', life: 3000});
                                    }, 500);
                                }
                            }
                        } catch (error) {
                            this.$toast.add({severity:'error', summary: 'Bir Hata Oluştu', detail: error.message, life: 3500});
                        }
                        finally {
                            loader.hide();
                        }
                    }
                });
            }
        }
    }
</script>