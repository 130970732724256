<template>
    <div class="card p-fluid">
        <h5>DOKÜMAN YÖNETİMİ</h5>
        <div class="p-formgrid p-grid">
            <div class="p-field col-2">
                 <Button icon="pi pi-upload" class="p-button-secondary" label="Dosya Yükle" @click="displayDosyaYukle=true" />
            </div>
           
            <div class="p-field p-col-12">
                <DataTable :value="mainData"
                    :rowClass="rowClass"
                    responsiveLayout="scroll"
                    :rowHover="true"
                    sortMode="single">
            
                    <template #empty>
                        Yüklenmiş dosya bulunamadı.
                    </template>
                    <template #loading>
                        Yükleniyor...
                    </template>
                    <Column field="fileName" header="Dosya Adı">
                        <template #body="{data}">
                            <i class="pi pi-file-pdf" style="color:red"></i> {{data.fileName}}
                        </template>
                    </Column>
                    <Column field="noteText" header="Açıklama"></Column>
                    <Column headerStyle="width: 0.5rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
                        <template #body="{data}">
                            <div style="display: flex">
                                <Button icon="pi pi-download" class="p-button-rounded p-button-info" @click="DownloadDokumanYonetimi(data.AnnotationId)" />
                                <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="deleteFile(data.AnnotationId)" />
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>

    <Dialog v-model:visible="displayDosyaYukle" :breakpoints="{'960px': '90vw', '640px': '90vw'}" :style="{width: '50vw'}" header="Dosya Yükle" :modal="true" :closable="true" :showHeader="true" :draggable="true">
        <br>
        <div class="p-2">
            <div class="grid">
                <div class="col-12">
                    <div class="field p-fluid">
                        <label for="subject">Açıklama</label>
                        <InputText id="subject" type="text" v-model="subject" />
                    </div>
                </div>
                <div class="col-12">
                    <div class="p-field">
                        <FileUpload ref="fileUpload" :key="fileUploadTemp" :customUpload="true" @uploader="fileUploader" :multiple="false" accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
text/plain, application/pdf" :maxFileSize="10000000" :invalidFileSizeMessage="'{0}: dosya boyutu limitini aştınız, {1} daha küçük bir dosya yükleyiniz.'" :fileLimit="1" >
                            <template #empty>
                                <p>Yüklenecek dosyayı buraya taşıyınız</p>
                            </template>
                        </FileUpload>
                    </div>
                </div>
            </div>
        </div>
    </Dialog>
</template>
<script>

    import CrmService from "../service/CrmService";
    export default {
        props: {
            objectTypeCode: {
                type: String,
                required: true
            },
            logicalName: {
                type: String,
                required: true
            },
            objectId: {
                type: String,
                required: true
            },
        },
        data() {
            return {
                crmService: null,
                mainData: [],
                fileUploadTemp: 0,
                displayDosyaYukle: false,

                subject: ''
            }
        },
        async created() {
            this.crmService = new CrmService();

            this.OnLoad();
        },
        methods: {
            async OnLoad(){
                try {
                    let data = await this.crmService.GetAnnotationList(this.objectId);
                    if (data.jsonData.length > 0) {
                        this.mainData = data.jsonData;
                    }
                    else {
                        this.mainData = [];
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            async DownloadDokumanYonetimi(AnnotationId){
                try {
                    window.open(process.env.VUE_APP_SERVICEURL + '/global/GetAnnotationFile/' + AnnotationId, '_blank');
                } catch (error) {
                    console.log(error);
                }
            },
            fileUploader(event){
                console.log(event);

                let loader = this.$loading.show({
                    container: this.$refs.mainContainer
                });

                let postData = {
                    objectTypeCode: this.objectTypeCode,
                    logicalName : this.logicalName,
                    objectId: this.objectId,
                    subject: this.subject,
                    noteText: this.subject
                };
debugger;
                var formData = new FormData();
                if (event.files.length > 0) {

                    for( var i = 0; i < event.files.length; i++ ){
                        let file = event.files[i];
                        formData.append('files[' + i + ']', file);
                    }
                }

                this.crmService.CreateAnnotationFile(formData, postData)
                .then(response => {
                    this.fileUploadTemp++;
                    loader.hide();

                    if (response.hata == false) {
                        this.$toast.add({severity:'success', summary: 'Dosya Yükleme', detail:'Başarıyla Kaydedildi', life: 5000});
                        this.$refs.fileUpload.clear();
                        this.displayDosyaYukle = false;
                        this.OnLoad();
                    }
                    else {
                        this.$toast.add({severity:'error', summary: 'Dosya Yüklenemedi!', detail: response.hataAciklamasi, life: 5000});
                    }
                })
                .catch(error => {
                    loader.hide();
                    this.$toast.add({severity:'error', summary: 'Dosya Yüklenemedi!', detail: error, life: 5000});
                })
            },
            async deleteFile(AnnotationId){
                this.$confirm.require({
                    message: 'Seçili dosya silinecek emin misiniz?',
                    header: 'Silme Onayı',
                    icon: 'pi pi-exclamation-triangle',
                    acceptClass: 'p-button-danger',
                    acceptLabel: 'Evet',
                    rejectLabel: 'Hayır',
                    accept: async () => {                        
                        let loader = this.$loading.show({
                            container: this.$refs.smsContainer
                        });

                        try {
                            const response = await this.crmService.DeleteRecordById("annotation", AnnotationId, this.logicalName);
                            debugger;
                            if (response) {
                                if (response.hata == true) {
                                    this.$toast.add({severity:'error', summary: 'Silinemedi', detail: response.hataAciklamasi, life: 5000});
                                }else {
                                    loader.hide();
                                    setTimeout(() => {
                                        debugger;
                                        this.OnLoad();
                                        this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla silindi', life: 3000});
                                    }, 500);
                                }
                            }
                        } catch (error) {
                            this.$toast.add({severity:'error', summary: 'Bir Hata Oluştu', detail: error.message, life: 3500});
                        }
                        finally {
                            loader.hide();
                        }
                    }
                });
            }
        }
    }
</script>